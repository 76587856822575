import React from 'react'
import { Link, graphql } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import ShineText from '../components/ShineText'
import {GithubLink, InstagramLink} from '../components/SocialLinks'

const MilesElliott = styled.h1`
font-size: 6rem;
max-width: 100%;
text-transform: uppercase;
color: var(--primeColor);
transform: var(--perspectiveTransform);
transform-origin: left;
padding-left: 2rem;
margin-bottom: 0;
line-height: 0.8;
margin-top: 6rem;
@media screen and (max-width: 30em) {
  font-size: 20vw;
  margin: 0 auto;
  display: inline-block;
  padding-left: 1.5rem;
}
`
const Tagline = styled.p`
font-size: 2rem;
margin: 0;
padding-left: 2rem;
line-height: 1.2;
transform: var(--perspectiveTransform);
transform-origin: left;
  color: var(--offPrimeGray);
@media screen and (max-width: 30em) {
  padding-left: 1.5rem;
}
`

const Socials = styled.ul`
list-style-type: none;
transform: var(--perspectiveTransform);
transform-origin: left;
padding-left: 2rem;
margin-left: -1rem;
@media screen and (max-width: 30em) {
  padding-left: 1.5rem;
}
`

const SocialItem = styled.li`
display: inline-block;
& > a {
padding: 0 1rem;
}
`


class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const {title, tagline, social} = data.site.siteMetadata
    const posts = data.allMdx.edges
    return (
      <Layout location={this.props.location} title={title}>
        <SEO
          title="Home"
          keywords={[`blog`, `gatsby`, `javascript`, `react`]}
        />

        <MilesElliott>{title}</MilesElliott>
        <Tagline>{tagline}</Tagline>
        <Socials>
          <SocialItem>
            <InstagramLink isBig user={social.instagram} />
            </SocialItem>
            <SocialItem>
            <GithubLink isBig user={social.github} />
            </SocialItem>
        </Socials>
        
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        tagline
        social {
          github
          instagram
        }
      }
      
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMM DD")
            title
          }
        }
      }
    }
  }
`
